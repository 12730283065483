<template>
    <v-card>
        <v-toolbar color="grey darken-3" dark elevation="1">
            <v-toolbar-title>
                {{ title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text class="pa-0">
            <v-stepper v-model="stepper" flat color="success">
                <v-stepper-header>
                    <v-stepper-step
                            color="success"
                            :complete="formValid1"
                            step="1"
                    >
                        Основне
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step
                            color="success"
                            :complete="formValid2"
                            step="2"
                    >
                        Власник
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step
                            color="success"
                            :complete="formValid3"
                            step="3"
                    >
                        Нормативні параметри
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step
                        color="success"
                        :complete="formValid4"
                        step="4"
                    >
                        Послуги/Групи тарифів
                    </v-stepper-step>
                  <v-stepper-step
                      color="success"
                      :complete="formValid5"
                      step="5"
                  >
                    Договір
                  </v-stepper-step>
                </v-stepper-header>
                <v-stepper-items>
                    <v-stepper-content step="1">
                        <div class="pb-1">
                            <v-form v-model="formValid1" ref="form_step_1">
                                <v-row class="dense-wrapper">
                                    <v-col cols="12">
                                        <v-switch
                                                color="grey darken-2"
                                                v-model="not_living"
                                                :label="not_living ? 'Нежитлове приміщення (увімкнено)' : 'Нежитлове приміщення (виммкнено)'"
                                                class="mt-1" :ripple="false"
                                                hide-details/>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                      <AddressElementSelect v-model="cityId"
                                                            label="Населений пункт" filled
                                                            select_type="city" req
                                                            :disabled="create_from_building"
                                                            :auto_fill_when_first="true"
                                      />
                                    </v-col>
                                    <v-col cols="12" :md="!buildingType ? 12 : 9">
                                      <AddressElementSelect :parent_id="cityId" v-model="streetId"
                                                            label="Вулиця(-ки)" filled req
                                                            select_type="street" use_parent_id
                                                            :disabled="!cityId || create_from_building"
                                                            :auto_fill_when_first="true"
                                                            @selectChanged="streetChange"
                                      />
                                    </v-col>
                                    <v-col cols="12" md="12" v-if="!buildingType">
                                      <AddressElementSelect :parent_id="streetId" v-model="buildingId"
                                                            label="Будинок(-ки)" filled req
                                                            select_type="building" use_parent_id
                                                            :disabled="!streetId || create_from_building"
                                                            :auto_fill_when_first="true"
                                                            @selectChanged="buildingChange"
                                      />
                                    </v-col>
                                    <v-col cols="12" md="3" v-else>
                                        <v-text-field type="text" hide-details filled label="№ будинку"
                                                      v-model="buildingNumber"
                                                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                                      :disabled="!streetId"
                                                      :class="buildingNumber ? '' : 'req-star'"
                                                      color="grey"
                                                      @change="buildingNumberChange"
                                        />
                                    </v-col>
                                    <v-col cols="12" md="12" v-if="not_living">
                                        <v-text-field type="text" hide-details filled label="Адреса нежитлового"
                                                      v-model="address"
                                                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                                      :class="address ? '' : 'req-star'"
                                                      color="grey"
                                        />
                                    </v-col>
                                    <v-col cols="12" md="6" style="display: flex; ">
                                        <v-text-field type="text" hide-details filled label="Особовий рахунок"
                                                      v-model="personId"
                                                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                                      :class="personId ? '' : 'req-star'"
                                                      color="grey"
                                                      style="flex: 1"
                                                      :disabled="person_id_soap_get"
                                                      id="person_id"
                                        />

                                      <v-btn style="flex: 0 0 24px" width="24" min-width="24"
                                             @click="showPersonIdByTemplate"
                                             height="48" depressed class="ml-1" :ripple="false">
                                        <v-icon size="30" color="success">mdi-menu-down</v-icon>
                                      </v-btn>

                                      <v-menu v-model="show_person_ids_by_template"
                                              absolute
                                              :position-x="person_id_x"
                                              :position-y="person_id_y"
                                              offset-y
                                              :nudge-width="260"
                                      >
                                        <v-list dense nav>
                                          <v-list-item
                                              v-for="(item, i) in person_ids_by_template"
                                              :key="i"
                                              @click="setPersonIdFromTemplate(item)"
                                          >
                                            <v-list-item-icon class="mr-3">
                                              <v-icon color="success" size="19">
                                                mdi-account-outline
                                              </v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title>{{ item }}</v-list-item-title>
                                          </v-list-item>
                                        </v-list>
                                      </v-menu>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field type="text" hide-details filled label="№ Квартири"
                                                      v-model="flatNumber"
                                                      required
                                                      :rules="buildingType === 2 ? [] : [v => !!v || 'Це поле є обов’язковим']"
                                                      :class="{'req-star': buildingType !== 2 && !flatNumber}"
                                                      color="grey"
                                                      @change="flatNumberChange"
                                        />
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <Checker :value="checker_id" @autocompleteChange="checkerChange"
                                                 color="grey"
                                        />
                                    </v-col>
                                    <v-col cols="6">
                                      <v-text-field v-model="eis_code"
                                                    hide-details filled color="grey"
                                                    label="ЕІС код"
                                      />
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-text-field type="phone" hide-details filled label="Підїзд" v-model="entrance"
                                                      color="grey"
                                                      v-decimal
                                        />
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-text-field type="phone" hide-details filled label="Поверх" v-model="floor"
                                                      color="grey"
                                                      v-decimal
                                        />
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-text-field type="phone" hide-details filled label="Кімнат" v-model="rooms"
                                                      color="grey"
                                                      v-decimal
                                        />
                                    </v-col>
                                    <v-col cols="12" md="8">
                                        <v-select :items="flatOwnershipTypes" hide-details filled label="Тип власності"
                                                  v-model="flatOwnershipType"
                                                  required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                                  :class="flatOwnershipType ? '' : 'req-star'"
                                                  color="grey"
                                        />
                                    </v-col>
                                    <v-col cols="12" md="4" style="display: flex">
                                      <v-text-field hide-details filled label="Поштовий індекс" v-model="postal_index"
                                                    color="grey" v-integer id="postal_index"
                                      />
                                      <v-btn style="flex: 0 0 24px" width="24" min-width="24"
                                             @click="showPostalIndex"
                                             height="48" depressed class="ml-1" :ripple="false">
                                        <v-icon size="30" color="success">mdi-menu-down</v-icon>
                                      </v-btn>

                                      <v-menu v-model="show_postal_index_list"
                                              absolute
                                              :position-x="postal_index_x"
                                              :position-y="postal_index_y"
                                              offset-y
                                              :nudge-width="180"
                                      >
                                        <v-list dense nav>
                                          <v-list-item
                                              v-for="(item, i) in postal_index_list"
                                              :key="i"
                                              @click="setPostalIndex(item)"
                                          >
                                            <v-list-item-icon class="mr-3">
                                              <v-icon color="success" size="19">
                                                mdi-mail
                                              </v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title>{{ item }}</v-list-item-title>
                                          </v-list-item>
                                        </v-list>
                                      </v-menu>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-switch v-model="disable" hide-details color="grey darken-2"
                                                  :label="disable ? 'Закритий особовий рахунок' : 'Актуальний особовий рахунок'"
                                        />
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <date-component label="Дата закриття" v-model="disable_date"
                                                        :req="disable"
                                        />
                                    </v-col>
                                </v-row>
                            </v-form>
                        </div>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <div class="pb-1">
                            <v-form v-model="formValid2" ref="form_step_2">
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <v-text-field type="text" hide-details filled label="Власник"
                                                      v-model="flatOwner"
                                                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                                      :class="flatOwner ? '' : 'req-star'"
                                                      color="grey"
                                        />
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field type="text" hide-details filled label="Телефон"
                                                      v-model="phoneNumber" color="grey"/>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field type="text" hide-details filled label="Email" v-model="email"
                                                      color="grey"/>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </div>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                        <div class="pb-1">
                            <v-row>
                                <v-col cols="12">
                                    <date-component filled
                                                    v-model="account_month"
                                                    label="Дата початку нормативних параметрів"
                                                    :class_="account_month ? '' : 'req-star'"
                                    />
                                </v-col>
                                <v-col cols="12" md="12">
                                    <v-list subheader>
                                        <v-subheader>Відмітьте необхідні нормативні параметри</v-subheader>
                                        <template v-for="item in indicators_create">
                                            <v-list-item :key="`indicator_id-${item.indicator_id}`">
                                                <v-list-item-action>
                                                    <v-checkbox
                                                        v-model="item.active"
                                                        color="success"
                                                    ></v-checkbox>
                                                </v-list-item-action>

                                                <v-list-item-content class="d-flex">
                                                    <div style="flex: 0 0 200px">
                                                        {{ item.name }}
                                                    </div>
                                                    <div style="flex: 1">
                                                        <v-text-field
                                                            v-model="item.value"
                                                            label="Значення"
                                                            hide-details
                                                            filled
                                                            v-decimal
                                                            color="grey darken-2"
                                                            :disabled="!item.active"
                                                            required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                                            :class="item.value
                                                                ? ''
                                                                : item.active ? 'req-star' : ''"
                                                        />
                                                    </div>
                                                </v-list-item-content>

                                                <v-list-item-avatar color="grey lighten-4">
                                                    <v-icon :color="general_settings?.general_indicator?.value === item.indicator_id ? 'secondary' : 'success'">
                                                       {{ general_settings?.general_indicator?.value === item.indicator_id ? 'mdi-star' : 'mdi-ruler' }}
                                                    </v-icon>
                                                </v-list-item-avatar>
                                            </v-list-item>
                                            <v-divider :key="`divider_indicator_id-${item.indicator_id}`"/>
                                        </template>
                                    </v-list>
                                </v-col>
                            </v-row>
                        </div>
                    </v-stepper-content>

                    <v-stepper-content step="4" style="height: 70vh">
                        <div class="pb-1">
                            <v-row>
                                <v-col cols="12">
                                    <date-component filled
                                                    v-model="account_month"
                                                    label="Дата початку для послуг та груп тарифів"
                                                    :class_="account_month ? '' : 'req-star'"
                                    />
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-list subheader style="height: calc(70vh - 50px); overflow: auto;">
                                        <v-subheader>Відмітьте необхідні послуги</v-subheader>
                                        <template v-for="item in services_create">
                                            <v-list-item :key="`service_id-${item.service_id}`" class="list-item-custom">
                                                <v-list-item-action class="my-1">
                                                    <v-checkbox
                                                            v-model="item.active"
                                                            color="success"
                                                            class="my-0" hide-details
                                                    ></v-checkbox>
                                                </v-list-item-action>

                                                <v-list-item-content>
                                                    <v-list-item-title class="list-title">
                                                        {{ item.name }}
                                                    </v-list-item-title>
                                                </v-list-item-content>

                                              <v-list-item-action class="my-1">
                                                    <v-icon :color="getAccountIcon(item.icon, 'color')" size="26">
                                                        {{ getAccountIcon(item.icon, 'icon') }}
                                                    </v-icon>
                                                </v-list-item-action>
                                            </v-list-item>
                                            <v-divider :key="`service_divider_${item.service_id}`"/>
                                        </template>
                                    </v-list>
                                </v-col>
                                <v-divider vertical></v-divider>
                                <v-col cols="12" md="6">
                                    <v-list subheader style="height: calc(70vh - 50px); overflow: auto;">
                                        <v-subheader>Відмітьте необхідні групи тарифів</v-subheader>
                                        <template v-for="item in tariff_group_create">
                                            <v-list-item :key="`tariff_group_type_id-${item.tariff_group_type_id}`" class="list-item-custom">
                                                <v-list-item-action class="my-1">
                                                    <v-checkbox
                                                            v-model="item.active"
                                                            color="success"
                                                            class="my-0" hide-details
                                                    ></v-checkbox>
                                                </v-list-item-action>

                                                <v-list-item-content class="py-1">
                                                    <v-list-item-title class="list-title">
                                                        {{ item.name }}
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-divider :key="`divider_tariff_group_type_id${item.tariff_group_type_id}`"/>
                                        </template>
                                    </v-list>
                                </v-col>
                            </v-row>
                        </div>
                    </v-stepper-content>

                    <v-stepper-content step="5" style="height: 70vh">
                      <div class="pb-1">
                        <v-row>
                          <v-col cols="12" md="12">
                            <v-list subheader style="max-height: calc(70vh - 50px); overflow: auto;">
                              <v-subheader>Відмітьте договори для створення</v-subheader>
                              <template v-for="item in contracts_create">
                                <v-list-item :key="`contract_id-${item.service_id}`">
                                  <v-list-item-action>
                                    <v-checkbox
                                        v-model="item.active"
                                        color="success"
                                    ></v-checkbox>
                                  </v-list-item-action>

                                  <v-list-item-content class="d-flex align-center">
                                    <div style="flex: 0 0 200px; margin-right: 12px">
                                      {{ item.name }}
                                    </div>
                                    <div style="flex: 0 0 180px; margin-right: 12px">
                                      <v-text-field
                                          v-model="item.number"
                                          label="Номер договору"
                                          hide-details
                                          filled
                                          v-decimal
                                          color="grey darken-2"
                                          :disabled="!item.active"
                                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                          :class="item.number
                                                                  ? ''
                                                                  : item.active ? 'req-star' : ''"
                                      />
                                    </div>
                                    <div style="flex: 1; margin-bottom: 2px">
                                      <date-component
                                          filled
                                          v-model="item.date"
                                          label="Дата договору"
                                          color="grey darken-2"
                                          :disabled="!item.active"
                                          :req="item.active"
                                          :class_="item.date
                                                            ? ''
                                                            : item.active ? 'req-star' : ''"
                                      />
                                    </div>
                                  </v-list-item-content>

                                  <v-list-item-avatar color="grey lighten-4">
                                    <v-icon :color="getAccountIcon(item.icon, 'color')">
                                      {{ getAccountIcon(item.icon, 'icon') }}
                                    </v-icon>
                                  </v-list-item-avatar>
                                </v-list-item>
                                <v-divider :key="`divider_contract_id-${item.service_id}`"/>
                              </template>
                            </v-list>
                          </v-col>
                        </v-row>
                      </div>
                    </v-stepper-content>

                </v-stepper-items>
            </v-stepper>
        </v-card-text>
        <v-card-actions>
            <v-spacer/>
            <v-btn
                    depressed text
                    color="success"
                    v-show="stepper > 1"
                    @click="doStepperValidationLeft"
            >
                Попередня
            </v-btn>
            <v-btn
                    depressed text
                    color="success"
                    v-show="stepper < 5"
                    :disabled="next_button_disable"
                    @click="doStepperValidationRight"
            >
                Наступна
            </v-btn>
            <v-btn depressed text color="secondary darken-1"
                   :disabled="stepper < 4 || !form_ready"
                   class="button-accept"
                   @click.stop="createFlat"
                   :loading="loading"
            >
                <v-icon left>mdi-content-save</v-icon>
                Зберегти
            </v-btn>
            <v-btn depressed text tile color="grey" @click="closeModal">
                <v-icon left>mdi-close</v-icon>
                Відмінити
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import {flat_ownership_types_select, getAccountIcon} from "@/utils/icons";
import {CREATE_FLAT, UPDATE_FLAT} from "@/store/actions/flat";
import {mapGetters} from 'vuex'
import {ALERT_SHOW} from "@/store/actions/alert";
import flatAPI from "@/utils/axios/flat"
import servicesAPI from "@/utils/axios/service"
import tariffGroupAPI from "@/utils/axios/tariff_group"
import indicatorTypeAPI from "@/utils/axios/flat_indicator"
import soapAPI from "@/utils/axios/soap"


export default {
    name: "HWP_Modal_Flat",
    props: ['title', 'item', 'dialog'],
    components: {
      AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
      Checker: () => import("@/components/autocomplite/Checker")
    },
    data() {
        return {
            stepper: 1,
            formValid1: false,
            formValid2: false,
            formValid3: false,
            formValid4: false,
            formValid5: false,
            watcher: null,
            dialog_watcher: null,
            personId: this.item.person_id || '',
            buildingType: this.item.buildingType || null,
            buildingNumber: null,
            flatNumber: this.item.flat_number || '',
            flatOwnershipType: this.item.flat_type || '',
            phoneNumber: this.item.phone_number || '',
            email: this.item.email || '',
            flatOwner: this.item.flat_owner_full_name || '',
            buildingId: this.item.building_id || null,
            streetId: this.item.street_id || null,
            cityId: this.item.city_id || null,
            isNew: this.item.id === undefined,
            create_from_building: this.item.create_from_building || false,
            flatOwnershipTypes: flat_ownership_types_select,
            entrance: this.item.entrance || 0,
            floor: this.item.floor || 0,
            rooms: this.item.rooms || 0,
            disable: this.item.disable || false,
            disable_date: this.item.disable_date || null,
            checker_id: this.item.checker_id || null,
            not_living: this.item.not_living || false,
            address: this.item.address || '',
            building_not_living_name: '',
            street_not_living_name: '',
            person_id_soap_get: false,
            person_id_soap: null,
            account_month: null,
            services_create: [],
            tariff_group_create: [],
            indicators_create: [],
            contracts_create: [],
            postal_index: this.item.postal_index,
            loading: false,
            person_ids_by_template: [],
            show_person_ids_by_template: false,
            person_id_x: 0,
            person_id_y: 0,
            postal_index_list: [],
            show_postal_index_list: false,
            postal_index_x: 0,
            postal_index_y: 0,
            eis_code: null
        }
    },
    methods: {
        showPersonIdByTemplate() {
          const el = document.getElementById('person_id')
          const coordinates = el.getBoundingClientRect()

          this.person_id_x = coordinates.x - 10
          this.person_id_y = coordinates.y + coordinates.height

          if (this.person_ids_by_template.length) {
            this.show_person_ids_by_template = true
          }
        },
        showPostalIndex() {
          const el = document.getElementById('postal_index')
          const coordinates = el.getBoundingClientRect()

          this.person_id_x = coordinates.x - 10
          this.person_id_y = coordinates.y + coordinates.height

          if (this.postal_index_list.length) {
            this.show_postal_index_list = true
          }
        },
        setPersonIdFromTemplate(payload) {
          this.personId = payload
          this.show_person_ids_by_template = false
        },
        setPostalIndex(payload) {
          this.postal_index = payload
          this.show_postal_index_list = false
        },
        getPersonIdByTemplate() {
          const person_id_by_template = this.general_settings?.person_id_by_template?.value

          if (!person_id_by_template) return

          this.person_ids_by_template = []

          if (!this.buildingType && this.streetId && this.buildingId) {
            const payload = {
              street_id: this.streetId,
              building_id: this.buildingId,
              building_number: null,
              flat_number: this.flatNumber || null
            }

            flatAPI.get_person_id_by_template(payload)
                .then(response => response.data)
                .then(data => this.person_ids_by_template = data)
                .catch(err => {
                  const error = err.response.data.detail;
                  this.loading = false
                  this.$store.dispatch(ALERT_SHOW, {
                    text: error,
                    color: 'error lighten-1'
                  })
                })
                .finally(() => {
                  const el = document.getElementById('person_id')
                  const coordinates = el.getBoundingClientRect()

                  this.person_id_x = coordinates.x - 10
                  this.person_id_y = coordinates.y + coordinates.height

                  if (this.person_ids_by_template.length) {
                    this.show_person_ids_by_template = true
                  }
                })
          }

          if (this.buildingType && this.streetId && this.buildingNumber) {
            const payload = {
              street_id: this.streetId,
              building_id: null,
              building_number: this.buildingNumber,
              flat_number: this.flatNumber || null
            }

            flatAPI.get_person_id_by_template(payload)
                .then(response => response.data)
                .then(data => this.person_ids_by_template = data)
                .catch(err => {
                  const error = err.response.data.detail;
                  this.loading = false
                  this.$store.dispatch(ALERT_SHOW, {
                    text: error,
                    color: 'error lighten-1'
                  })
                })
                .finally(() => {
                  const el = document.getElementById('person_id')
                  const coordinates = el.getBoundingClientRect()

                  this.person_id_x = coordinates.x - 10
                  this.person_id_y = coordinates.y + coordinates.height

                  if (this.person_ids_by_template.length) {
                    this.show_person_ids_by_template = true
                  }
                })
          }
        },
        getPostalIndex() {
          this.postal_index_list = []

          if (!this.buildingType && this.streetId && this.buildingId) {
            const payload = {
              street_id: this.streetId,
              building_id: this.buildingId
            }

            flatAPI.get_postal_indexes(payload)
                .then(response => response.data)
                .then(data => {
                  this.postal_index_list = data
                  if (this.postal_index_list.length === 1) {
                    this.postal_index = this.postal_index_list[0]
                  }
                })
                .catch(err => {
                  const error = err.response.data.detail;
                  this.loading = false
                  this.$store.dispatch(ALERT_SHOW, {
                    text: error,
                    color: 'error lighten-1'
                  })
                })
                .finally(() => {
                  const el = document.getElementById('postal_index')
                  const coordinates = el.getBoundingClientRect()

                  this.postal_index_x = coordinates.x - 10
                  this.postal_index_y = coordinates.y + coordinates.height

                  if (this.postal_index_list.length > 1) {
                    this.show_postal_index_list = true
                  }
                })
          }

          if (this.buildingType && this.streetId && this.buildingNumber) {
            const payload = {
              street_id: this.streetId,
              building_id: null
            }

            flatAPI.get_postal_indexes(payload)
                .then(response => response.data)
                .then(data => {
                  this.postal_index_list = data
                  if (this.postal_index_list.length === 1) {
                    this.postal_index = this.postal_index_list[0]
                  }
                })
                .catch(err => {
                  const error = err.response.data.detail;
                  this.loading = false
                  this.$store.dispatch(ALERT_SHOW, {
                    text: error,
                    color: 'error lighten-1'
                  })
                })
                .finally(() => {
                  const el = document.getElementById('postal_index')
                  const coordinates = el.getBoundingClientRect()

                  this.postal_index_x = coordinates.x - 10
                  this.postal_index_y = coordinates.y + coordinates.height

                  if (this.postal_index_list.length > 1) {
                    this.show_postal_index_list = true
                  }
                })
          }
        },
        getAccountIcon,
        doStepperValidationRight() {
            if (this.stepper === 1) {
                this.formValid1 = this.$refs.form_step_1.validate()
                if (this.formValid1) {
                    this.stepper = 2
                    return;
                }
            }
            if (this.stepper === 2) {
                this.formValid2 = this.$refs.form_step_2.validate()
                if (this.formValid2) {
                    this.stepper = 3
                    return;
                }
            }
            if (this.stepper === 3) {
                const indicator_valid = this.indicators_create
                    .filter(item => item.active && (item.value === null
                        || item.value === undefined || item.value === '')).length === 0
                if (indicator_valid) {
                    this.formValid3 = true
                    this.stepper = 4
                    return;
                }
            }

            if (this.stepper === 4) {
              this.contracts_create.forEach(item => item.active = false)

              this.services_create.filter(item => item.active).forEach(item => {
                const contract_row = this.contracts_create.find(c => c.service_id === item.service_id)
                if (contract_row) {
                  contract_row.active = true
                  if (this.general_settings?.person_id_by_template?.value) {
                    contract_row.number = this.personId
                    if (this.account_month) {
                      contract_row.date = this.account_month
                    }
                  }
                }
              })
              this.formValid4 = true
              this.stepper = 5;
            }
        },
        doStepperValidationLeft() {
            if (this.stepper <= 1) {
                return
            }
            this.stepper -= 1
        },
        streetChange(payload) {
          this.building_not_living_name = ''

          if (this.not_living) {
            const text = payload?.text || ''
            let split = text.split(',')
            if (split.length > 1) {
              this.building_not_living_name = split[1].trim()
            } else {
              this.building_not_living_name = text.trim()
            }

            this.address = this.building_not_living_name
          }

          this.getPersonIdByTemplate()
          this.getPostalIndex()

        },
        buildingChange(payload) {
            this.buildingId = payload?.value || null
            this.building_not_living_name = ''
            if (this.not_living) {
                const text = payload?.text || ''
                let split = text.split(',')
                if (split.length > 1) {
                  this.building_not_living_name = split[1].trim()
                } else {
                  this.building_not_living_name += ' ' + text.trim()
                }

                this.address = this.building_not_living_name
            }
          this.getPersonIdByTemplate()
          this.getPostalIndex()
        },
        checkerChange(payload) {
            this.checker_id = payload?.value || null
        },
        buildingNumberChange() {
            this.buildingNumber = this.buildingNumber.trim()
            if (this.not_living) {
                this.address = `${this.street_not_living_name}${this.buildingNumber ? ` ${this.buildingNumber}` : ""}`
            }
            this.getPersonIdByTemplate()
            this.getPostalIndex()
        },
        flatNumberChange() {
            this.flatNumber = this.flatNumber.trim()
            this.getPersonIdByTemplate()
            this.getPostalIndex()
        },
        closeModal() {
            this.$emit('closeModal')
            this.personId = this.item.person_id
            this.buildingType = this.item.buildingType
            this.flatNumber = this.item.flat_number
            this.flatOwnershipType = this.item.flat_type
            this.phoneNumber = this.item.phone_number
            this.email = this.item.email
            this.flatOwner = this.item.flat_owner_full_name
            this.buildingId = this.item.building_id
            this.streetId = this.item.street_id
            this.cityId = this.item.city_id
            this.buildingNumber = null
            this.create_from_building = this.item.create_from_building || false
            this.entrance = this.item.entrance || 0
            this.floor = this.item.floor || 0
            this.rooms = this.item.rooms || 0
            this.disable = this.item.disable || false
            this.disable_date = this.item.disable_date || null
            this.checker_id = this.item.checker_id || null
            this.not_living = this.item.not_living || false
            this.address = this.item.address
            this.street_not_living_name = ''
            this.building_not_living_name = ''
            this.person_id_soap_get = false
            this.person_id_soap = null
        },
        createFlat() {
            this.formValid1 = this.$refs.form_step_1.validate()
            if (!this.formValid1) {
                this.formValid1 = false
                this.$store.commit(ALERT_SHOW, {
                    text: `Населений пункт, вулиця, ${!this.buildingType ? 'будинок' : '№ будинку'}${this.not_living ? ', адреса нежитлового' : ''}, особовий рахунок, № квартири, тип власності, власник - мають бути заповнені`,
                    color: 'error lighten-1'
                })
                return;
            }

            this.formValid2 = this.$refs.form_step_2.validate()
            if (!this.formValid2) {
                this.formValid2 = false
                this.$store.commit(ALERT_SHOW, {
                    text: 'Власник має бути заповнений',
                    color: 'error lighten-1'
                })
                return;
            }

          this.formValid5 = this.contracts_create
              .filter(item => item.active && ((item.number === null
                  || item.number === undefined || item.number === '')
                  || (item.date === null))).length === 0

          if (!this.formValid5) {
            this.formValid2 = false
            this.$store.commit(ALERT_SHOW, {
              text: 'Дата та номер договору мають бути заповнені',
              color: 'error lighten-1'
            })
            return;
          }

            const services = this.services_create.filter(item => item.active)
            const groups = this.tariff_group_create.filter(item => item.active)
            const indicators = this.indicators_create.filter(item => item.active)
            const contracts = this.contracts_create.filter(item => item.active)

            if ((services.length || groups.length) && !this.account_month) {
                this.$store.commit(ALERT_SHOW, {
                    text: 'Вкажіть дату початку для послуг та груп тарифів',
                    color: 'error lighten-1'
                })
                return;
            }

            const indicator_error = this.indicators_create
                .filter(item => item.active && (item.value === null
                    || item.value === undefined
                    || item.value === ''
                )).length > 0

            if (indicator_error || !this.account_month) {
                this.$store.commit(ALERT_SHOW, {
                    text: 'Вкажіть дату початку для послуг та груп тарифів',
                    color: 'error lighten-1'
                })
                return;
            }

            const payload = {
                person_id: this.personId,
                building_id: this.buildingId,
                street_id: this.streetId,
                building_number: this.buildingNumber,
                flat_number: this.flatNumber,
                phone_number: this.phoneNumber,
                email: this.email,
                flat_type: this.flatOwnershipType,
                flat_owner_full_name: this.flatOwner,
                entrance: this.entrance,
                floor: this.floor,
                rooms: this.rooms,
                disable: this.disable || false,
                disable_date: this.disable_date || null,
                checker_id: this.checker_id || null,
                not_living: this.not_living || false,
                address: this.address || null,
                account_month: this.account_month || null,
                postal_index: this.postal_index,
                eis_code: this.eis_code,
                services,
                groups,
                indicators,
                contracts
            }
            const person_id = this.personId
            const person_id_soap = this.person_id_soap
            const person_id_soap_get = this.person_id_soap_get
            const address = this.address || `${this.buildingNumber}_${this.flatNumber}_${this.flatOwner}`
            const eps_id = this.organization.eps_id

            this.loading = true

            flatAPI.check_person_id_id(
                {
                    person_id: this.personId,
                    person_hash: '',
                })
                .then(response => response.data)
                .then(data => {
                    if (data) {
                        if (this.isNew) {
                            this.$store.dispatch(CREATE_FLAT, payload).then(res => {
                                if (res) {
                                    if (person_id === person_id_soap && person_id_soap_get && eps_id) {
                                        soapAPI.create_person_id(
                                            {
                                                person_id,
                                                address
                                            }
                                        )
                                            .catch(err => {
                                                const error = err.response.data.detail;
                                                this.loading = false
                                                this.$store.dispatch(ALERT_SHOW, {
                                                    text: error,
                                                    color: 'error lighten-1'
                                                })
                                            })
                                    }
                                    this.loading = false
                                    this.closeModal()
                                }
                            })
                        } else {
                            payload.id = this.buildingId
                            this.$store.dispatch(UPDATE_FLAT, payload).then(res => {
                                if (res) {
                                    this.loading = false
                                    this.closeModal()
                                }
                            })
                        }
                    } else {
                        this.loading = false
                        this.$store.dispatch(ALERT_SHOW, {
                            text: 'Абонент із таким особовим рахунком вже існує',
                            color: 'error lighten-1'
                        })
                    }
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    this.loading = false
                    this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                })
                .finally(() => {
                  this.loading = false
                })
        },
        watch_item() {
        this.watcher = this.$watch(
            'item',
            {
              deep: true,
              immediate: true,
              handler(payload) {
                this.account_month = this.current_month
                this.personId = payload.person_id
                this.buildingType = payload.buildingType
                this.flatNumber = payload.flat_number
                this.flatOwnershipType = payload.flat_type
                this.phoneNumber = payload.phone_number
                this.email = payload.email
                this.flatOwner = payload.flat_owner
                this.buildingId = payload.building_id
                this.streetId = payload.street_id
                this.cityId = payload.city_id
                this.buildingNumber = null
                this.create_from_building = payload.create_from_building || false
                this.entrance = payload.entrance
                this.floor = payload.floor
                this.rooms = payload.rooms
                this.not_living = payload.not_living
                this.address = payload.address
                this.services_create = []
                this.tariff_group_create = []
                this.indicators_create = []
                this.contracts_create = []
                this.stepper = 1
                this.formValid1 = false
                this.formValid2 = false
                this.formValid3 = false
                this.formValid4 = false
                this.postal_index = payload.postal_index
                this.checker_id = payload.checker_id
                this.eis_code = payload.eis_code

                indicatorTypeAPI.select_type()
                    .then(response => response.data)
                    .then(data => {
                      const main = this.general_settings?.general_indicator?.value
                      const first = data.filter(item => item.value === main)
                      if (first.length) {
                        first[0].active = true
                      }
                      const other = data.filter(item => item.value !== main).sort((a, b) => a.text.localeCompare(b.text));
                      const new_data = first.concat(other)
                      new_data.forEach(item => {
                        this.indicators_create.push(
                            {
                              indicator_id: item.value,
                              name: item.text,
                              active: item.active || false
                            }
                        )
                      })
                    })

                servicesAPI.select(false)
                    .then(response => response.data)
                    .then(data => {
                      data.forEach(item => {
                        this.contracts_create.push(
                            {
                              service_id: item.value,
                              name: item.text,
                              icon: item.type,
                              number: null,
                              date: null,
                              active: false
                            }
                        )

                        this.services_create.push(
                            {
                              service_id: item.value,
                              name: item.text,
                              icon: item.type
                            }
                        )
                      })
                    })

                tariffGroupAPI.tariff_group_type_select({})
                    .then(response => response.data)
                    .then(data => {
                      data.forEach(item => {
                        this.tariff_group_create.push(
                            {
                              tariff_group_type_id: item.value,
                              name: item.text
                            }
                        )
                      })
                    })

                if (this.item.id === undefined && this.general_settings && this.dialog) {
                  this.checker_id = this.userCheckerId
                  this.flatOwnershipType = this.general_settings?.standart_owner_type?.value || null
                  if (this.general_settings?.person_id_from_wsdl?.value) {
                    if (!this.organization.eps_id) {
                      this.$store.commit(ALERT_SHOW, {text: 'Не вказано код організації', color: 'secondary'})
                    }
                    if (this.general_settings?.person_id_from_wsdl?.value && this.organization.eps_id) {
                      soapAPI.get_person_id()
                          .then(response => response.data)
                          .then(data => {
                            this.personId = `${data}`
                            if (data) {
                              this.person_id_soap = this.personId
                              this.person_id_soap_get = true
                            } else {
                              this.person_id_soap = null
                              this.person_id_soap_get = false
                            }
                          })
                          .catch(err => {
                            const error = err.response.data.detail;
                            this.person_id_soap = null
                            this.person_id_soap_get = false
                            this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                          })
                    }

                  }
                  if (this.general_settings.person_id_auto.value === true) {
                    const person_id_by_template = this.general_settings?.person_id_by_template?.value

                    if (!person_id_by_template) {
                      const payload = {
                        person_hash: '',
                        person_id: this.personId ? this.personId : '',
                      }

                      flatAPI.get_max_person_id(payload)
                          .then(response => response.data)
                          .then(data => {
                            this.personId = data
                          })
                          .catch(err => {
                            const error = err.response.data.detail;
                            this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                          })
                    }
                  }
                }

              }
            }
        )
        },
        watch_dialog() {
          this.dialog_watcher = this.$watch(
              'dialog',
              {
                immediate: true,
                handler(payload) {
                  if (payload) {
                    this.watch_item()
                  } else {
                    if (this.watcher) {
                      this.watcher()
                    }
                  }
                }
              }
          )
        }
    },
    computed: {
        ...mapGetters({
            general_settings: 'getGeneralSettings',
            organization: 'getCurrentOrganization',
            current_month: 'getCurrentMonth',
            userCheckerId: 'getUserCheckerId'
        }),
        form_ready() {
            return (this.formValid1
                && this.formValid2
                && this.account_month
                && this.indicators_create
                    .filter(item => item.active && (item.value === null
                        || item.value === undefined
                        || item.value === ''
                    )).length === 0
            )
        },
        next_button_disable() {
            if (this.stepper === 1) {
                return !this.formValid1
            }
            if (this.stepper === 2) {
                return !(this.formValid1 && this.formValid2)
            }
            if (this.stepper === 3) {
                return this.indicators_create
                    .filter(item => item.active && (item.value === null
                        || item.value === undefined
                        || item.value === ''
                    )).length > 0
            }
          if (this.stepper === 4) {
            return false
          }
            return this.stepper >= 5;
        }
    },
    created() {
      this.watch_dialog()
    },
    beforeDestroy() {
      if (this.watcher) {
        this.watcher()
      }
      if (this.dialog_watcher) {
        this.dialog_watcher()
      }
    }
}
</script>

<style lang="scss" scoped>
  .list-item-custom {
    min-height: 40px !important;
  }
  .list-title {
    font-size: .94rem;
  }
</style>
